import React from 'react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import style from './index.module.scss'

interface IconProps {
  size?: 'small' | 'medium'
  type: string
  className?: string
}

const Icon: React.FC<IconProps> = ({
  size = 'medium',
  type,
  className,
  ...iconProps
}) => {
  const IconSVG: React.ComponentType<React.SVGProps<SVGSVGElement>> = dynamic(
    () => import(`./assets/${size}/${type}.inline.svg`)
  )

  return IconSVG ? (
    <IconSVG className={classNames(style.Icon, className)} {...iconProps} />
  ) : null
}

export default Icon
