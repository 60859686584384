import { isSameOrAfterToday } from './date-helpers'

export const filterExpiredOccurrence = occurrence => {
  if (occurrence && occurrence.times.length) {
    const times = occurrence.times.filter(time =>
      isSameOrAfterToday(time.endAt, time.timeZone)
    )
    return { ...occurrence, times }
  }
  return occurrence
}
