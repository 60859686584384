import React, { useContext, ReactElement, FC } from 'react'

import { CallToAction } from '@scentregroup/ui'
import ExternalLink from '../external-link'
import canonicalLink from '../../helpers/canonical-link'
import analyticsUtils from '../../helpers/analytics-utils'
import { GetHrefContext, AgentInfoContext } from '../../context'

export interface PageSharerPropTypes {
  urlToShare?: string
  shareButtonType?: ShareButtonType
}

interface AnalyticsTags {
  eventAction: string
  eventCategory: string
  event: string
}

export enum ShareButtonType {
  EVENT = 'Event',
}

interface AnalyticsArgs {
  shareButtonType?: ShareButtonType
  isNativeShareAvailable?: boolean
  isCancel?: boolean
}

function getAnalyticsTags({
  shareButtonType,
  isNativeShareAvailable,
  isCancel,
}: AnalyticsArgs): AnalyticsTags {
  // N.B. in some cases below "event" is as in "analytics event" and in
  // other cases its as in "event details page"
  const event = analyticsUtils.events.USER_CLICK
  const { PAGE_SHARE, EVENT_SHARE } = analyticsUtils.categories
  const { SHARE_THIS_EVENT, SHARE_THIS_PAGE, SHARE_TO_FACEBOOK, CANCEL_SHARE } =
    analyticsUtils.actions
  let eventAction: string = SHARE_THIS_PAGE
  let eventCategory: string = PAGE_SHARE
  if (shareButtonType === 'Event') {
    eventAction = SHARE_THIS_EVENT
    eventCategory = EVENT_SHARE
  }
  if (!isNativeShareAvailable) {
    eventAction = SHARE_TO_FACEBOOK
  }
  if (isCancel) {
    eventAction = CANCEL_SHARE
  }
  return { event, eventCategory, eventAction }
}

const PageSharer: FC<React.PropsWithChildren<PageSharerPropTypes>> = ({
  urlToShare,
  shareButtonType,
  ...otherProps
}): ReactElement => {
  const getHref = useContext(GetHrefContext)
  const { isMobile } = useContext(AgentInfoContext)
  const share =
    typeof window !== 'undefined' &&
    window.navigator.share &&
    window.navigator.share.bind(window.navigator)
  /*
  N.B. we determine at render time whether or not native share capability
  is available. This effects both click behaviour but also button
  labelling. So if someone with a share capable device visits the page 
  directly and triggers a SSR they see a flash - the button changes from 
  the native share version to the fallback Facebook one when the page 
  rehydrates.
  
  We don't want "Share this <Page>" labelled buttons to go directly to
  Facebook for UX reasons so we can't just determine which behaviour to do
  at click time (when we obviously know whether native share is available)

  We might with some additional work optimistically guess whether share is 
  supported by checking the user agent on SSR, and then maybe transforming
  the button from "Share this <Page>" to "Share to Facebook" on first click
  if failed (not great UX but hopefully a rare case)

  But then again eventually if we build our own (limited) non-facebook share
  functionality as a fallback for desktops etc, the problem goes away.
  */
  const currentUrl = canonicalLink.canonical(getHref())
  const url = urlToShare || currentUrl
  const fbShareUrl = `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}`
  const isNativeShareAvailable = Boolean(share)
  const analyticsTags = getAnalyticsTags({
    shareButtonType,
    isNativeShareAvailable,
  })
  const cancelTags = getAnalyticsTags({
    shareButtonType,
    isNativeShareAvailable,
    isCancel: true,
  })
  const onClick = async (): Promise<void> => {
    if (share) {
      try {
        analyticsUtils.DEPRECATED_gaTagTracking(analyticsTags)
        await share({ url })
      } catch {
        analyticsUtils.DEPRECATED_gaTagTracking(cancelTags)
      }
    }
  }
  if (share && isMobile) {
    return (
      <CallToAction onClick={onClick} {...otherProps}>
        {`Share this ${shareButtonType}`}
      </CallToAction>
    )
  } else {
    return (
      <CallToAction
        element={ExternalLink}
        url={fbShareUrl}
        {...otherProps}
        {...analyticsTags}
      >
        Share to Facebook
      </CallToAction>
    )
  }
}
export default PageSharer
