import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@scentregroup/ui'
import PotentialLink from '../../auto-potential-link'
import style from './index.module.scss'

const ArticleHeading = ({ children, link, dataTestId, type = 'large' }) => (
  <PotentialLink link={link} className={style.link}>
    <Heading type={type} data-test-id={dataTestId}>
      {children}
    </Heading>
  </PotentialLink>
)

ArticleHeading.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string,
  type: PropTypes.oneOf(['large', 'medium', 'small']),
  dataTestId: PropTypes.string,
}

export default ArticleHeading
