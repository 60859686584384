import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../icon'
import style from './index.module.scss'

const IconRow: React.FC<React.PropsWithChildren<IconRowProps>> = ({
  text,
  className,
  icon,
}): JSX.Element => (
  <div className={classNames(style.location, className)}>
    {icon && <Icon type={icon} size="small" className={style.pinIcon} />}
    {text}
  </div>
)

interface IconRowProps {
  text?: string
  icon?: string
  className?: string
}

IconRow.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
}

export default IconRow
