import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../icon'
import { isEmpty, isNull } from 'lodash'
import { summariseOccurrences } from '../../helpers/occurrences'
import { occurrencesType } from './../../types'
import ListEventTimes from './list-event-times'
import style from './index.module.scss'

const Occurrences = ({ occurrences, className }) => {
  if (
    isEmpty(occurrences.times) ||
    isEmpty(occurrences.times[0]) ||
    isNull(occurrences.times[0].endAt)
  ) {
    return null
  }

  const isInline = occurrences.times.length === 1 || occurrences.summary

  return (
    <ul
      className={classNames(
        style.Occurrences,
        { [style.inline]: isInline },
        className
      )}
    >
      {isInline ? (
        <li className={classNames(style.occurrence, style.occurrenceHasIcon)}>
          <Icon
            type="hours"
            size="small"
            className={classNames(style.occurrenceIcon)}
          />
          {summariseOccurrences(occurrences.times, occurrences.timeZone)}
        </li>
      ) : (
        <Fragment>
          <li key="icon">
            <Icon
              type="hours"
              size="small"
              className={classNames(style.occurrenceIcon)}
            />
          </li>
          <li key="times" className={classNames(style.occurrenceTableWrap)}>
            <ListEventTimes occurrences={occurrences} />
          </li>
        </Fragment>
      )}
    </ul>
  )
}

Occurrences.propTypes = {
  className: PropTypes.string,
  occurrences: occurrencesType.isRequired,
}

export default Occurrences
