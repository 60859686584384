import React, { Component } from 'react'
import classNames from 'classnames'
import Icon from '../icon'
import { DateTime } from '../../helpers/date-helpers'
import {
  formatDay,
  formatDayNumber,
  formatTime,
  formatMonth,
} from '../../helpers/date-format-helpers'
import { filterExpiredOccurrence } from '../../helpers/filter-expired-occurance'
import { occurrencesType } from './../../types'
import style from './index.module.scss'

class ListEventTimes extends Component {
  constructor(props) {
    super(props)
    this.toggleExpanded = this.toggleExpanded.bind(this)
    this.state = {
      expanded: false,
      filteredOccurrences: filterExpiredOccurrence(this.props.occurrences),
    }
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded })
  }

  listEventTimes(list, timeZone) {
    return list.map(occurrence => {
      const startAt = DateTime.fromISO(occurrence.startAt, { timeZone })
      const endAt = DateTime.fromISO(occurrence.endAt, { timeZone })
      return (
        <li key={occurrence.startAt} className={classNames(style.occurrence)}>
          <span className={classNames(style.hiddenMobile, style.occurrenceDay)}>
            {formatDay(startAt)}{' '}
          </span>
          <span className={classNames(style.occurrenceDate)}>
            {formatDayNumber(startAt)} {formatMonth(startAt)}{' '}
          </span>
          <span className={classNames(style.occurrenceTime)}>
            {formatTime(startAt)}
            {' - '}
            {formatTime(endAt)}{' '}
          </span>
        </li>
      )
    })
  }

  render() {
    const ITEMS_SHOWN = 5
    const {
      filteredOccurrences: { times, timeZone },
    } = this.state
    const list = this.state.expanded ? times : times.slice(0, ITEMS_SHOWN)

    if (times.length === 0) {
      return <div>No occurrences or past event</div>
    }

    if (times.length > ITEMS_SHOWN) {
      return (
        <ul className={classNames(style.occurrenceTable)}>
          {this.listEventTimes(list, timeZone)}
          <li className={classNames(style.occurrence)}>
            <button
              className={classNames(style.showMore)}
              onClick={this.toggleExpanded}
            >
              Show {this.state.expanded ? 'less' : 'more'} dates
              <Icon
                type={this.state.expanded ? 'chevron-up' : 'chevron-down'}
                className={style.carrot}
              />
            </button>
          </li>
        </ul>
      )
    }

    return (
      <ul className={classNames(style.occurrenceTable)}>
        {this.listEventTimes(list, timeZone)}
      </ul>
    )
  }
}

ListEventTimes.propTypes = {
  occurrences: occurrencesType,
}

export default ListEventTimes
