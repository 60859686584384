import { shape, string, object, array, arrayOf, bool, number } from 'prop-types'

export * from './types/marketplace-categories'
export * from './types/marketplace-coupon'
export * from './types/marketplace-product'

export type PartiallyRequired<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>

export const occurrencesType = shape({
  summary: bool.isRequired,
  times: arrayOf(
    shape({
      startAt: string.isRequired,
      endAt: string,
    })
  ),
  timeZone: string.isRequired,
})

export const imageType = shape({
  alt: string,
  src: string.isRequired,
})

export const contentAnalyticsMetaDataType = shape({
  correlationId: string,
  title: string,
  promotion: shape({
    creative: string,
  }),
})

export const entryType = shape({
  entry: shape({
    title: string.isRequired,
    category: string.isRequired,
    desktopImage: imageType,
    mobileImage: imageType,
    body: string.isRequired,
    startTime: string,
    endTime: string,
    termsAndConditions: string,
    physicalAddress: string,
    telephoneNumber: string,
    store: object,
    startAt: string,
    endAt: string,
    occurrences: occurrencesType,
    analyticsMetaData: contentAnalyticsMetaDataType,
  }),
})

export const callToActionType = arrayOf(
  shape({
    category: string.isRequired,
    address: string.isRequired,
  })
)

export const centreType = shape({
  sys: shape({
    id: string.isRequired,
  }),
  title: string.isRequired,
  telephoneNumber: string.isRequired,
  emailAddress: string.isRequired,
  facebook: string.isRequired,
  instagram: string.isRequired,
  physicalAddress: string.isRequired,
  state: string.isRequired,
  postcode: string.isRequired,
  suburb: string.isRequired,
  navigationMenu: array,
  slug: string.isRequired,
  country: string.isRequired,
  jibestreamVenueId: number,
})

export const promotionType = shape({
  image: shape({
    url: string,
    alt: string,
  }).isRequired,
  title: string.isRequired,
  heading: string.isRequired,
  shortDescription: string.isRequired,
  callToActionLabel: string.isRequired,
  callToActionAddress: string.isRequired,
  eventCategory: string,
  eventAction: string,
})

export const EventElement = shape({
  target: shape({
    value: string,
  }),
})

type ProductPrice = number

export interface IProductPrices {
  originalPrice: ProductPrice
  effectivePrice: ProductPrice
  discountedPrice?: ProductPrice
}

export interface IProductPriceRange {
  min: ProductPrice
  max: ProductPrice
}

type Percentage = number

export interface IPercentageRange {
  min: Percentage
  max: Percentage
}

export interface IMarketplaceProductPromotion {
  campaignId: string
  title: string
  directPromotion?: {
    sys: {
      id: string
    }
    talonOneId?: string
    termsAndConditions: string
    title: string
    slug: string
  }
}

export interface IMarketplaceCartItemAppliedPromotion {
  campaignId: string
  title: string
  directPromotion?: {
    sys: {
      id: string
    }
    talonOneId?: string
    termsAndConditions: string
    title: string
    slug: string
  }
  couponCode?: string | null | undefined
}

export interface IUniversalCart {
  url: string
  count: number
}

export enum CreditCard {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  AMEX = 'amex',
  GOOGLEPAY = 'googlepay',
  APPLEPAY = 'applepay',
}

type Fn = (arg: any) => any

type FirstParam<F extends Fn> = Parameters<F>[0]

export type NarrowParameter<F extends Fn, K extends keyof FirstParam<F>> = (
  arg: Pick<FirstParam<F>, K>
) => ReturnType<F>

export type ReplaceKeysValue<
  BaseType,
  OldKey extends keyof BaseType,
  NewValue,
> = {
  [Key in keyof BaseType]: Key extends OldKey ? NewValue : BaseType[Key]
}
