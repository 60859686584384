/* eslint-disable react/no-multi-comp */
import React, { Component, Fragment, FC, ReactNode, ReactElement } from 'react'
import PropTypes from 'prop-types'
import Icon from '../icon'
import classNames from 'classnames'
import analyticsUtils from '../../helpers/analytics-utils'
import { getBrowser, isMobile } from '../../helpers/get-browser'
import style from './index.module.scss'

type Content = ReactNode

export interface RowEntry {
  title?: Content
  content?: Content
}

const rowEntryPropTypes = {
  title: PropTypes.node,
  content: PropTypes.node,
}

export type ExpandTitle =
  | string
  | {
      opened: Content
      closed: Content
    }

const expandTitlePropTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    opened: PropTypes.node.isRequired,
    closed: PropTypes.node.isRequired,
  }),
])

interface InfoAccordionRowProps extends RowEntry {
  extraHeight?: boolean
  rowBorder?: string
  theme?: string
}

const infoAccordionRowPropTypes = {
  ...rowEntryPropTypes,
  extraHeight: PropTypes.bool,
  rowBorder: PropTypes.string,
}

const infoAccordionRowContentPropTypes = {
  content: PropTypes.node,
}

export interface MetaRowEntry {
  rows: RowEntry[]
  rowType?: string | null
  expandTitle?: ExpandTitle | null
}

const metaRowPropTypes = PropTypes.shape({
  rows: PropTypes.arrayOf(PropTypes.shape(rowEntryPropTypes).isRequired)
    .isRequired,
  rowType: PropTypes.string,
  expandTitle: expandTitlePropTypes,
})

export enum Theme {
  Parking = 'parking',
}

enum InteractionKind {
  Touch = 'Touch',
  Click = 'Click',
}

interface AccordionHeadingProps {
  toggleHandler: (interactionKind: InteractionKind) => void
  isExpanded?: boolean
  expandTitle: ExpandTitle
  rowBorder: string
  theme?: string
}

const accordionHeadingPropTypes = {
  expandTitle: expandTitlePropTypes.isRequired,
  rowBorder: PropTypes.string.isRequired,
  toggleHandler: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  theme: PropTypes.string,
}

interface InfoAccordionWrapperProps {
  metaRow: MetaRowEntry
  theme?: string
}

interface InfoAccordionWrapperState {
  isExpanded: boolean
}

export interface InfoAccordionProps {
  metaRows: MetaRowEntry[]
  purpose?: string
  theme?: string
}

const infoAccordionPropTypes = {
  metaRows: PropTypes.arrayOf(metaRowPropTypes.isRequired).isRequired,
  purpose: PropTypes.string,
  theme: PropTypes.oneOf(
    Object.keys(Theme).map(theme => Theme[theme as keyof typeof Theme])
  ),
}

const InfoAccordionRowContent: FC<React.PropsWithChildren<RowEntry>> = ({
  content,
}): ReactElement => (
  <React.Fragment>
    <div className={style.parkingContent}>{content}</div>
    <span className={style.accordionBorder} />
  </React.Fragment>
)

const InfoAccordionRow: FC<React.PropsWithChildren<InfoAccordionRowProps>> = ({
  title,
  content,
  extraHeight = false,
  rowBorder,
  theme,
}): ReactElement => (
  <React.Fragment>
    <h3
      className={classNames([
        style.accordionRow,
        extraHeight && style.extraHeight,
      ])}
    >
      {title && <span className={style.rowTitle}>{title}</span>}
      {content && (
        <span
          className={classNames(style.rowContent, {
            [style.parking]: theme === 'parking',
          })}
        >
          {content}
        </span>
      )}
    </h3>
    <span className={rowBorder} />
  </React.Fragment>
)

const AccordionHeading: FC<React.PropsWithChildren<AccordionHeadingProps>> = ({
  toggleHandler,
  isExpanded,
  expandTitle,
  rowBorder,
  theme,
}): ReactElement => {
  const { opened, closed } =
    typeof expandTitle === 'string'
      ? { opened: expandTitle, closed: expandTitle }
      : expandTitle
  let content = isExpanded ? opened : closed
  let start = 0
  /* 
    The duration for a Click is 125 milliseconds
    The duration for a Long(Press/Touch) is 500 milliseconds
  */
  const longPressDuration = 500
  if (typeof content === 'string') {
    content = <h2>{content}</h2>
  }
  const hideBorder = theme === 'parking' && isExpanded
  return (
    <Fragment>
      <h3 className={style.headerHeading}>
        <Icon
          type={isExpanded ? 'chevron-up' : 'chevron-down'}
          className={classNames(style.expandButton, {
            [style.parking]: theme === 'parking',
          })}
        />
        <button
          className={style.btnOveride}
          type="button"
          aria-expanded={isExpanded}
          onClick={() => toggleHandler(InteractionKind.Click)}
          onTouchStart={() => {
            start = new Date().getTime()
          }}
          onTouchEnd={() => {
            const newDate = new Date()
            const touchDuration = newDate.getTime() - start
            // Disables the touch if durations is <= 500 milliseconds
            if (touchDuration <= longPressDuration) {
              toggleHandler(InteractionKind.Touch)
            }
          }}
        >
          <InfoAccordionRow theme={theme} content={content} />
        </button>
      </h3>
      {!hideBorder && <span className={rowBorder} />}
    </Fragment>
  )
}

class InfoAccordionWrapper extends Component<
  InfoAccordionWrapperProps,
  InfoAccordionWrapperState
> {
  public static propTypes = { metaRow: metaRowPropTypes }

  public state = {
    isExpanded: false,
  }

  private toggleHandler(interactionKind: InteractionKind): void {
    const deviceMobile = isMobile(getBrowser(this.context))
    const { Touch, Click } = InteractionKind
    const touchAndMobile = interactionKind === Touch && deviceMobile
    const clickAndDesktop = interactionKind === Click && !deviceMobile
    if (touchAndMobile || clickAndDesktop) {
      this.toggleRow()
    }
  }

  private toggleRow(): void {
    const { isExpanded } = this.state
    this.setState({ isExpanded: !isExpanded })
    analyticsUtils.DEPRECATED_gaTagTracking({
      event: analyticsUtils.events.USER_CLICK,
      eventCategory: analyticsUtils.categories.EVENT_DETAILS_INTERACTION,
      eventLabel: isExpanded
        ? analyticsUtils.actions.LESS
        : analyticsUtils.actions.MORE,
    })
  }

  public render(): ReactElement {
    const { isExpanded } = this.state
    const { metaRow, theme } = this.props
    const rows = !metaRow.expandTitle || isExpanded ? metaRow.rows : []
    const isOcc = metaRow.rowType === 'occurrence'
    const { accordionBorder, indentBorder } = style
    const rowBorder: string =
      (isOcc && rows.length === 1) || theme === 'parking'
        ? accordionBorder
        : `${indentBorder} ${accordionBorder}`
    return (
      <li>
        {metaRow.expandTitle && (
          <AccordionHeading
            toggleHandler={interactionKind =>
              this.toggleHandler(interactionKind)
            }
            expandTitle={metaRow.expandTitle}
            isExpanded={isExpanded}
            rowBorder={isExpanded ? rowBorder : accordionBorder}
            theme={theme}
          />
        )}
        {rows.map((row, inx) => {
          if (theme === 'parking') {
            return <InfoAccordionRowContent key={inx} content={row.content} />
          }
          return (
            <InfoAccordionRow
              key={inx}
              title={row.title}
              content={row.content}
              extraHeight={isOcc}
              rowBorder={rows.length - 1 === inx ? accordionBorder : rowBorder}
            />
          )
        })}
      </li>
    )
  }
}

const InfoAccordion: FC<React.PropsWithChildren<InfoAccordionProps>> = ({
  metaRows,
  purpose,
  theme,
}): ReactElement => (
  <ul
    className={classNames(style.infoAccordionWrapper, {
      [style.parking]: theme === 'parking',
    })}
    aria-label={purpose}
    aria-level={2}
    role="heading"
  >
    {metaRows.map((x, inx) => (
      <InfoAccordionWrapper key={inx} metaRow={x} theme={theme} />
    ))}
  </ul>
)

InfoAccordionRow.propTypes = infoAccordionRowPropTypes
InfoAccordionRowContent.propTypes = infoAccordionRowContentPropTypes
AccordionHeading.propTypes = accordionHeadingPropTypes
InfoAccordion.propTypes = infoAccordionPropTypes

export default InfoAccordion
