import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CallToAction, Heading } from '@scentregroup/ui'
import { occurrencesType, callToActionType } from '../../types'
import ExternalLink from '../external-link'
import IconRow from '../icon-row'
import Occurrences from '../occurrences'
import InfoAccordion from '../info-accordion'
import PageSharer from '../page-sharer'
import EventNotification from '../event-notification'
import ArticleHeading from './article-heading'
import style from './index.module.scss'

const Article = ({
  occurrences,
  superheading,
  heading,
  subheading,
  times,
  location,
  className,
  children,
  link,
  callsToAction,
  hoursText,
  infoAccordion,
  pastEventsAccordion,
  shareButtonType,
  headerDataTestId,
  context,
  dataTestId,
  headingType = 'large',
}) => (
  <article
    className={classNames(
      style.Article,
      style.evolved,
      {
        [style[`${context}Context`]]: context !== undefined,
      },
      className
    )}
  >
    <div className={style.containFlexChildrenForIE11}>
      {superheading && (
        <Heading
          type="extrasmall"
          className={style.superHeading}
          data-test-id={headerDataTestId}
        >
          {superheading}
        </Heading>
      )}

      {superheading && superheading.toUpperCase() === 'EVENT' && (
        <EventNotification className={style.warningNotification} />
      )}
      <ArticleHeading type={headingType} link={link} dataTestId={dataTestId}>
        {heading}
      </ArticleHeading>
      {subheading && (
        <Heading
          type="small"
          className={style.subHeading}
          data-test-id={dataTestId}
        >
          {subheading}
        </Heading>
      )}

      {location && location.length && (
        <IconRow text={location} icon="location-drop-pin" />
      )}
      {hoursText && <IconRow text={hoursText} icon="hours" />}
      {occurrences && (
        <Occurrences occurrences={occurrences} location={location} />
      )}
      {infoAccordion && (
        <InfoAccordion metaRows={infoAccordion} purpose="Event details" />
      )}
      {pastEventsAccordion && (
        <InfoAccordion metaRows={pastEventsAccordion} purpose="Past events" />
      )}
      {times && <div className={style.times}>{times}</div>}
      {(callsToAction || shareButtonType) && (
        <div className={style.ctaWrapper}>
          {(callsToAction || []).map(cta => (
            <CallToAction
              fullWidth
              element={ExternalLink}
              className={style.cta}
              key={cta.address}
              url={cta.address}
            >
              {cta.category}
            </CallToAction>
          ))}
          {shareButtonType && (
            <PageSharer
              fullWidth
              className={style.cta}
              shareButtonType={shareButtonType}
            />
          )}
        </div>
      )}
      <div className={style.body}>{children}</div>
    </div>
  </article>
)

Article.propTypes = {
  occurrences: occurrencesType,
  superheading: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  times: PropTypes.string,
  location: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  link: PropTypes.string,
  headingType: PropTypes.oneOf(['large', 'medium', 'small']),
  callsToAction: callToActionType,
  hoursText: PropTypes.string,
  infoAccordion: PropTypes.array,
  pastEventsAccordion: PropTypes.array,
  shareButtonType: PropTypes.string,
  headerDataTestId: PropTypes.string,
  context: PropTypes.oneOf(['half', 'feature', 'openingHours']),
  dataTestId: PropTypes.string,
}

export default Article
