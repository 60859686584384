import { ICategory } from './marketplace-categories'
import { IProductImage, IProductModifier } from './product'

interface IMarketplaceProductInformationText {
  label: string
  text: string
}

interface IMarketplaceProductInformationTable {
  label: string
  entries: IMarketplaceProductInformationText[] | undefined
}

export type IMarketplaceProductInformationEntry =
  | IMarketplaceProductInformationText
  | IMarketplaceProductInformationTable

interface IGetMarketPlaceProductVariantResponse {
  id: string
  title: string
  sku: string
  originalPrice: number
  discountPrice?: number
  returnable?: boolean
  images: IProductImage[]
  optionValues: IGetMarketplaceProductVariantOption[]
  quantity: number
  issues: IIssue[]
  createdAt?: string
  information: IMarketplaceProductInformationEntry[]
}

interface IGetMarketplaceProductVariantOption {
  id: string
  optionId: string
  displayName: string
  value: string
}

export enum MarketplaceProductOptionType {
  DROPDOWN = 'DROPDOWN',
}

export interface IMarketplaceVisualGuide {
  name: string
  url: string
  description: string
}

interface IGetMarketplaceProductOption {
  id: string
  type: MarketplaceProductOptionType
  displayName: string
  visualGuide: IMarketplaceVisualGuide | null
  values: { id: string; label: string; colourHexCode?: string }[]
}

export interface IGetMarketplaceProductResponse {
  id: string
  title: string
  description?: string
  careInstructions?: string
  warnings?: string
  warranty?: string
  brand: string
  category: Pick<ICategory, 'path'>
  variants: IGetMarketPlaceProductVariantResponse[]
  options: IGetMarketplaceProductOption[]
}

export interface IMarketplaceProductOptionValue {
  id: string
  label: string
  colourHexCode?: string
}

export interface IMarketplaceProductOption {
  id: string
  type: MarketplaceProductOptionType
  displayName: string
  visualGuide: IMarketplaceVisualGuide | null
  values: IMarketplaceProductOptionValue[]
}

export interface IMarketplaceProductVariant {
  id: string
  title: string
  sku: string
  originalPrice?: number
  discountPrice?: number
  returnable?: boolean
  images: IProductImage[]
  // TODO: update or remove
  optionValues: IGetMarketplaceProductVariantOption[]
  quantity: number
  issues: IIssue[]
  createdAt?: string
  information: IMarketplaceProductInformationEntry[]
}

interface IMarketplaceProduct {
  id: string
  title: string
  shop: IMarketplaceProductShop
  description?: string
  careInstructions?: string
  warnings?: string
  warranty?: string
  variants: IMarketplaceProductVariant[]
  options: IMarketplaceProductOption[]
}

export interface IMarketplaceProductShop {
  retailerId?: string
  retailerSlug?: string
  id: number
  name?: string
  logoUrl?: string
}

export interface IGetMarketplaceShopResponse {
  id: number
  name: string
  retailer?: IRetailer
}

export interface IRetailer {
  sys: {
    id: string
  }
  title: string
  retailerLogoSet?: IRetailerLogo
}

interface IRetailerLogo {
  coloured?: {
    url: string
  }
}

export enum StockLevel {
  IN_STOCK = 'IN_STOCK',
  LOW_STOCK = 'LOW_STOCK',
  UNAVAILABLE = 'UNAVAILABLE',
}
export enum IssueType {
  OutOfStock = 'OUT_OF_STOCK',
  LowStock = 'LOW_STOCK',
  NotEnoughStock = 'NOT_ENOUGH_STOCK',
}

export enum IssueSeverity {
  Warning = 'WARNING',
  Error = 'ERROR',
}

export interface IIssue {
  type: IssueType
  severity: IssueSeverity
}

export interface IIssueWithMessage extends IIssue {
  message: string
}

export interface IMarketplaceProductAvailability {
  stockLevel: StockLevel
}

export class MarketplaceProduct implements IMarketplaceProduct {
  public readonly id: string
  public readonly title: string
  public readonly shop: IMarketplaceProductShop
  public readonly brand: string
  public readonly category: Pick<ICategory, 'path'>
  public readonly description?: string
  public readonly careInstructions?: string
  public readonly warnings?: string
  public readonly warranty?: string
  public readonly variants: IMarketplaceProductVariant[]
  public readonly options: IMarketplaceProductOption[]
  public readonly modifiers: IProductModifier[]

  public constructor(
    marketplaceProduct: IGetMarketplaceProductResponse,
    marketplaceShop: IGetMarketplaceShopResponse
  ) {
    this.id = marketplaceProduct.id
    this.title = marketplaceProduct.title
    this.shop = {
      retailerId: marketplaceShop.retailer?.sys.id,
      id: marketplaceShop.id,
      name: marketplaceShop.retailer?.title ?? marketplaceShop.name,
      logoUrl: marketplaceShop.retailer?.retailerLogoSet?.coloured?.url,
    }
    this.brand = marketplaceProduct.brand
    this.category = marketplaceProduct.category
    this.description = marketplaceProduct.description
    this.careInstructions = marketplaceProduct.careInstructions
    this.warnings = marketplaceProduct.warnings
    this.warranty = marketplaceProduct.warranty
    this.variants = marketplaceProduct.variants
    // TODO: implement options & modifiers
    // or remove current use of these attributes
    this.options = this.toProductOptions(marketplaceProduct.options)
    this.modifiers = []
  }

  private toProductOptions = (
    options?: IGetMarketplaceProductOption[]
  ): IGetMarketplaceProductOption[] =>
    options?.filter((o): o is IGetMarketplaceProductOption => o !== null) || []
}
